<div class="dialog-wrap {{inputMode}}" #dialogWrap>
    <nb-card [nbSpinner]="loading" style="max-height: initial; margin: 0 auto; width: 22cm;" class="small-header">
        <nb-card-header>
            <ngx-card-header [option]="{controlOption: {}}" [size]="size" [icon]="favicon" [title]="(title | translate:{definition: '', action: cms.translate.instant('Common.print')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-header>
            <div class="row">
                <div class="col-sm-12">
                    <ngx-form-group [formGroup]="filter" name="Product" label="Lọc theo sản phẩm" [allowCopy]="false" [required]="false" [touchedValidate]="false">
                        <ngx-select2 formControlName="Product" [select2Option]="select2OptionForProducts" [data]="productList"></ngx-select2>
                    </ngx-form-group>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body class="print-body" #printContent>
            <div id="print-area" [formGroup]="choosedForms">
                <!-- <div class="blabel" *ngFor="let item of this.data; let i = index;" style="position: relative;"> -->
                <div class="blabel" *ngFor="let item of this.choosedForms.controls; let i = index;" style="position: relative;" [formGroup]="item">
                    <!-- <div class="find-order">{{item.Container.FindOrder || 'UNDEF'}}</div> -->
                    <div style="position:absolute; bottom: 0.5rem; right: 0.5rem;" class="print-choosed">
                        <nb-checkbox formControlName="Choosed"></nb-checkbox>
                    </div>
                    <div style="display: flex;">
                        <div style="flex: 1;">
                            <div class="access-number">Truy xuất: {{item.value.AccessNumber}}</div>
                        </div>
                        <div>
                            <div class="product-price"><ng-container *ngIf="isShowPrice">{{item.value.Price | currency:'VND'}}/</ng-container>{{item.value.Unit | objecttext}}</div>
                        </div>
                    </div>
                    <div style="overflow: hidden; height: 19px;">
                        <!-- <img class="bar-code" src="{{item.value.BarCode}}"> -->
                        <ngx-barcode [bc-value]="item.value.BarCodeData" [bc-display-value]="false" [bc-format]="'CODE128'" [bc-height]="19" [bc-width]="0.9" [bc-margin]="0" bc-margin-top="0" bc-margin-bottom="0" bc-margin-left="0" bc-margin-right="0"></ngx-barcode>
                    </div>
                    <!-- <div style="display: flex; height: 4.5mm; align-items: center; justify-content: center;">
                    </div> -->
                    <div class="info">SKU: {{item.value.Product.Sku}} - {{item.value.Product | objecttext}}</div>
                    <div style="display: flex; clear: both;">
                        <!-- <img class="qr-code" src="{{item.value.QrCode}}"> -->
                        <ngx-qrcode [elementType]="'url'" [value]="item.value.QrCodeData" cssClass="qr-code" errorCorrectionLevel="L" [margin]="0" [width]="'9mm'" style="margin-right: 0.5mm;"></ngx-qrcode>
                        <div class="register-info" style="flex: 1">{{registerInfo.companyName}}<br>ĐC: {{registerInfo.address}}<br>ĐT: {{registerInfo.tel}}<br>Web: {{registerInfo.website}}</div>
                    </div>
                    <div class="probox-link">QRLink: {{item.value.QrCodeDataPreview}}</div>
                    <div class="section-break" *ngIf="item.value.IsEndSection"></div>
                </div>
                <div style="clear:both"></div>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <div class="buttons-row">
                <button nbButton hero status="primary" (click)="print(0)">
                    <nb-icon pack="eva" icon="printer"></nb-icon>{{'Common.print' | translate | headtitlecase}}
                </button>
                <ngx-pagination-control [total]="totalPage" [page]="page" (pageChange)="onPageChange($event)" [isProcessing]="loading"></ngx-pagination-control>
                <button nbButton hero status="danger" (click)="close()">
                    <nb-icon pack="eva" icon="close"></nb-icon>{{'Common.close' | translate | headtitlecase}}
                </button>
            </div>
        </nb-card-footer>
    </nb-card>
</div>